/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ErrorsType } from './errorsType';
import { ApplicationCustomType } from './applicationCustomType';
import { CommentsType } from './commentsType';


export interface NegativeReportType { 
    errors: ErrorsType;
    comments?: CommentsType;
    applicationCustom?: ApplicationCustomType;
}

