import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'mock-upreg',
  templateUrl: './upreg.component.html',
  styleUrls: ['./upreg.component.scss']
})
export class UpregComponent implements OnInit, OnDestroy {

  form!: FormGroup;
  subscription!: Subscription;

  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.subscription = this.route.queryParamMap.subscribe(map => {
      this.form = this.formBuilder.group({
        callbackUrl: [map.get('callback-url'), [Validators.required]],
        count: [map.get('count')],
        'provider-session-id': [map.get('provider-session-id'), [Validators.required]],
        'return-code': [1, [Validators.required]],
        message: [null]
      });
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  submit(): void {
    this.form?.markAllAsTouched();
    if (this.form?.valid) {
      const form: HTMLFormElement = document.createElement('form');
      const data: HTMLInputElement = document.createElement('input');
      data.name = 'data';
      data.value = JSON.stringify(this.form.getRawValue());
      data.hidden = true;
      form.action = this.form.get('callbackUrl')?.value;
      form.method = 'post';
      form.enctype = 'text/plain';
      form.append(data);
      document.body.append(form);
      form.submit();
    }
  }

}
