import { Component, OnInit } from '@angular/core';
import { GrundstueckDTO } from '../../../target/generated-sources/openapi';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mock-grundstueck-list',
  templateUrl: './grundstueck-list.component.html',
  styleUrls: ['./grundstueck-list.component.scss']
})
export class GrundstueckListComponent implements OnInit {

  list!: GrundstueckDTO[];

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.list = this.route.snapshot.data.list;
  }

}
