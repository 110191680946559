import { Component, OnDestroy, OnInit } from '@angular/core';
import { LayoutControlService } from '../service/layout-control.service';

@Component({
  selector: 'mock-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  constructor(
    public layoutControl: LayoutControlService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
