import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mock-app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.scss']
})
export class AppSidebarComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  toggleMenu(...elements: HTMLElement[]): boolean {
    for (const element of elements) {
      element.classList.toggle('expand');
    }
    return false;
  }

}
