/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ObjectType } from './objectType';
import { MessageGroupType } from './messageGroupType';
import { SendingApplicationType } from './sendingApplicationType';
import { ReferenceType } from './referenceType';


export interface ReportHeaderType { 
    senderId: string;
    recipientId?: Array<string>;
    messageId: string;
    referenceMessageId?: string;
    uniqueBusinessTransactionId?: string;
    ourBusinessReferenceId?: string;
    yourBusinessReferenceId?: string;
    messageType?: number;
    subMessageType?: string;
    messageGroup: MessageGroupType;
    sendingApplication: SendingApplicationType;
    object?: ObjectType;
    initialMessageDate?: string;
    action: number;
    testDeliveryFlag?: boolean;
    reference?: ReferenceType;
}

