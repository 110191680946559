<div [class.app-sidebar-expanded]="this.layoutControl.isSidebarExpanded()" class="app-layout">
  <mock-app-header></mock-app-header>
  <mock-app-sidebar></mock-app-sidebar>
  <main class="app-main">
    <div class="wrapper">
      <div class="page">
        <div class="page-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </main>
</div>
