/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CommentsType } from './commentsType';


export interface ErrorType { 
    errorKind: ErrorType.ErrorKindEnum;
    comments?: CommentsType;
}
export namespace ErrorType {
    export type ErrorKindEnum = 'NOT_VALID' | 'FILE_WITHOUT_REFERENCE' | 'REFERENCE_WITHOUT_FILE' | 'UNKNOWN_RECIPIENT';
    export const ErrorKindEnum = {
        NotValid: 'NOT_VALID' as ErrorKindEnum,
        FileWithoutReference: 'FILE_WITHOUT_REFERENCE' as ErrorKindEnum,
        ReferenceWithoutFile: 'REFERENCE_WITHOUT_FILE' as ErrorKindEnum,
        UnknownRecipient: 'UNKNOWN_RECIPIENT' as ErrorKindEnum
    };
}


