<nav [attr.aria-label]="'Header'"
     class="navbar navbar-expand navbar-dark bg-bedag-dark-violet-100 fixed-top app-header">
  <div class="app-header-container left-container ps-2">
    <button (click)="toggleSidebar()" class="btn btn-link text-white">
      <i class="fas fa-1.5x fa-bars"></i>
    </button>
    <a class="ms-3 fw-bold app-header-brand" routerLink="/">
      <span class="text-white">Mock Service</span>
    </a>
  </div>
  <div class="app-header-container middle-container"></div>
  <div class="app-header-container right-container pe-3"></div>
</nav>
