import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { GrundstueckControllerService, GrundstueckDTO } from '../../../target/generated-sources/openapi';

@Injectable({
  providedIn: 'root'
})
export class GrundstueckResolver implements Resolve<GrundstueckDTO> {

  constructor(private backend: GrundstueckControllerService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GrundstueckDTO> {
    const id = route.paramMap.get('id');
    if (!id) {
      return EMPTY;
    }
    return this.backend.getGrundstueckByIdOrEgrid(id);
  }
}
