/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CmisAllowableActionsType { 
    canDeleteObject?: boolean;
    canUpdateProperties?: boolean;
    canGetFolderTree?: boolean;
    canGetProperties?: boolean;
    canGetObjectRelationships?: boolean;
    canGetObjectParents?: boolean;
    canGetFolderParent?: boolean;
    canGetDescendants?: boolean;
    canMoveObject?: boolean;
    canDeleteContentStream?: boolean;
    canCheckOut?: boolean;
    canCancelCheckOut?: boolean;
    canCheckIn?: boolean;
    canSetContentStream?: boolean;
    canGetAllVersions?: boolean;
    canAddObjectToFolder?: boolean;
    canRemoveObjectFromFolder?: boolean;
    canGetContentStream?: boolean;
    canApplyPolicy?: boolean;
    canGetAppliedPolicies?: boolean;
    canRemovePolicy?: boolean;
    canGetChildren?: boolean;
    canCreateDocument?: boolean;
    canCreateFolder?: boolean;
    canCreateRelationship?: boolean;
    canCreateItem?: boolean;
    canDeleteTree?: boolean;
    canGetRenditions?: boolean;
    canGetACL?: boolean;
    canApplyACL?: boolean;
    any?: Array<object>;
    otherAttributes?: { [key: string]: string; };
}

