<div class="page--functionbar">
  <mock-functionbar>
    <mock-functionbar-item icon="fa-arrow-left" navigationLink=".." slot="left-container"
                           tooltip="common.back"></mock-functionbar-item>
  </mock-functionbar>
  <div>
    <div class="row">
      <div class="col">
        <mock-card title="Grundstück">
          <mock-form-control [label]="{'id': 'nummer', 'key': 'Nummer'}" [readonly]="true">
            <input type="text" class="form-control" [value]="grundstueck.nummer">
          </mock-form-control>
          <mock-form-control [label]="{'id': 'document', 'key': 'Dokument'}" [readonly]="true">
            <pre class="form-control">{{grundstueck.document}}</pre>
          </mock-form-control>
        </mock-card>
      </div>
    </div>
  </div>
</div>
