<div class="table-responsive">
  <table class="table table-striped table-hover">
    <thead>
    <tr>
      <th scope="col">ID</th>
      <th scope="col">Nummer</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let entity of list" [routerLink]="'./' + entity.id" class="navigation-table__row">
      <td>{{entity.id}}</td>
      <td>{{entity.nummer}}</td>
    </tr>
    </tbody>
  </table>
</div>
