import { Component, OnInit } from '@angular/core';
import { GrundstueckDTO, PersongbDTO, RechtDTO } from '../../../target/generated-sources/openapi';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mock-gbdbs-detail',
  templateUrl: './gbdbs-detail.component.html',
  styleUrls: ['./gbdbs-detail.component.scss']
})
export class GbdbsDetailComponent implements OnInit {

  entity!: GrundstueckDTO | PersongbDTO | RechtDTO;
  title!: string;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.entity = this.route.snapshot.data.entity;
    this.title = this.route.snapshot.data.title;
  }

}
