/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CmisAccessControlListType } from './cmisAccessControlListType';
import { CmisPropertiesType } from './cmisPropertiesType';
import { CmisRenditionType } from './cmisRenditionType';
import { CmisChangeEventType } from './cmisChangeEventType';
import { CmisAllowableActionsType } from './cmisAllowableActionsType';
import { CmisListOfIdsType } from './cmisListOfIdsType';


export interface CmisObjectType { 
    properties?: CmisPropertiesType;
    allowableActions?: CmisAllowableActionsType;
    relationship?: Array<CmisObjectType>;
    changeEventInfo?: CmisChangeEventType;
    acl?: CmisAccessControlListType;
    exactACL?: boolean;
    policyIds?: CmisListOfIdsType;
    rendition?: Array<CmisRenditionType>;
    any?: Array<object>;
    otherAttributes?: { [key: string]: string; };
}

