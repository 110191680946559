import { Component, OnInit } from '@angular/core';
import { GrundstueckDTO, PersongbDTO, RechtDTO } from '../../../target/generated-sources/openapi';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mock-gbdbs-list',
  templateUrl: './gbdbs-list.component.html',
  styleUrls: ['./gbdbs-list.component.scss']
})
export class GbdbsListComponent implements OnInit {

  list!: (GrundstueckDTO | PersongbDTO | RechtDTO)[];

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.list = this.route.snapshot.data.list;
  }

}
