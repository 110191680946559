/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CmisChangeEventType { 
    changeType: CmisChangeEventType.ChangeTypeEnum;
    changeTime: string;
    any?: Array<object>;
}
export namespace CmisChangeEventType {
    export type ChangeTypeEnum = 'CREATED' | 'UPDATED' | 'DELETED' | 'SECURITY';
    export const ChangeTypeEnum = {
        Created: 'CREATED' as ChangeTypeEnum,
        Updated: 'UPDATED' as ChangeTypeEnum,
        Deleted: 'DELETED' as ChangeTypeEnum,
        Security: 'SECURITY' as ChangeTypeEnum
    };
}


