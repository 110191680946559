/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Hash { 
    value: string;
    algorithm: Hash.AlgorithmEnum;
}
export namespace Hash {
    export type AlgorithmEnum = 'SHA-256' | 'SHA-384' | 'SHA-512' | 'SHA3-256' | 'SHA3-384' | 'SHA3-512';
    export const AlgorithmEnum = {
        Sha256: 'SHA-256' as AlgorithmEnum,
        Sha384: 'SHA-384' as AlgorithmEnum,
        Sha512: 'SHA-512' as AlgorithmEnum,
        Sha3256: 'SHA3-256' as AlgorithmEnum,
        Sha3384: 'SHA3-384' as AlgorithmEnum,
        Sha3512: 'SHA3-512' as AlgorithmEnum
    };
}


