<div class="row">
  <div class="col" *ngIf="form">
    <mock-card title="Zulassungsbestätigung(en) freigeben">
      <form [formGroup]="form" (ngSubmit)="submit()">
        <mock-form-control [label]="{'id': 'count', 'key': 'Anzahl Zulassungsbestätigung'}" [readonly]="true"
                           [innerControl]="form.get('count')">
          <input type="text" class="form-control" formControlName="count">
        </mock-form-control>
        <mock-form-control [label]="{'id': 'callbackUrl', 'key': 'Callback URL'}"
                           [innerControl]="form.get('callbackUrl')">
          <input type="text" class="form-control" formControlName="callbackUrl">
        </mock-form-control>
        <mock-form-control [label]="{'id': 'provider-session-id', 'key': 'Session ID'}"
                           [innerControl]="form.get('provider-session-id')">
          <input type="text" class="form-control" formControlName="provider-session-id">
        </mock-form-control>
        <mock-form-control [label]="{'id': 'return-code', 'key': 'Return Code'}" [innerControl]="form.get('return-code')">
          <input type="text" class="form-control" formControlName="return-code">
        </mock-form-control>
        <mock-form-control [label]="{'id': 'message', 'key': 'Message'}" [innerControl]="form.get('message')">
          <textarea type="text" class="form-control" formControlName="message"></textarea>
        </mock-form-control>
        <button class="btn btn-bedag-green-100" type="submit" [disabled]="form.invalid">
          Freigeben
        </button>
      </form>
    </mock-card>
  </div>
</div>
