import {Component, OnInit} from '@angular/core';
import {NotariatsgeschaeftControllerService, NotariatsgeschaeftDTO} from '../../../target/generated-sources/openapi';
import {ActivatedRoute} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'mock-notariatsgeschaeft-detail',
  templateUrl: './notariatsgeschaeft-detail.component.html',
  styleUrls: ['./notariatsgeschaeft-detail.component.scss']
})
export class NotariatsgeschaeftDetailComponent implements OnInit {

  notariatsgeschaeft!: NotariatsgeschaeftDTO;
  title!: string;
  info = '';
  error = '';

  constructor(private route: ActivatedRoute,
              private backend: NotariatsgeschaeftControllerService) {
  }

  ngOnInit(): void {
    this.notariatsgeschaeft = this.route.snapshot.data.entity;
    this.title = this.route.snapshot.data.title;
  }

  notifyJournalEntry(): void {
    if (this.notariatsgeschaeft.id) {
      this.backend.notifyJournalEntry(this.notariatsgeschaeft.id).subscribe(
        () => this.info = 'Erfolgreich gesendet',
        (err: HttpErrorResponse) => this.error = err.statusText);
    }
  }

  notifyRegistration(): void {
    if (this.notariatsgeschaeft.id) {
      this.backend.notifyRegistration(this.notariatsgeschaeft.id).subscribe(
        () => this.info = 'Erfolgreich gesendet',
        (err: HttpErrorResponse) => this.error = err.statusText);
    }
  }

  notifyRejection(): void {
    if (this.notariatsgeschaeft.id) {
      this.backend.notifyRejection(this.notariatsgeschaeft.id).subscribe(
        () => this.info = 'Erfolgreich gesendet',
        (err: HttpErrorResponse) => this.error = err.statusText);
    }
  }

  requestImprovedDocuments(): void {
    if (this.notariatsgeschaeft.id) {
      this.backend.requestImprovedDocuments(this.notariatsgeschaeft.id).subscribe(
        () => this.info = 'Dokumente erfolgreich nachgefordert',
        (err: HttpErrorResponse) => this.error = err.statusText);
    }
  }

  cancelRequestImprovedDocuments(): void {
    if (this.notariatsgeschaeft.id) {
      this.backend.cancelRequestImprovedDocuments(this.notariatsgeschaeft.id).subscribe(
        () => this.info = 'Nachforderung erfolgreich abgebrochen',
        (err: HttpErrorResponse) => this.error = err.statusText);
    }
  }

  changeDeadline(): void {
    if (this.notariatsgeschaeft.id) {
      this.backend.changeDeadline(this.notariatsgeschaeft.id).subscribe(
        () => this.info = 'Erfolgreich gesendet',
        (err: HttpErrorResponse) => this.error = err.statusText);
    }
  }
}
