<div class="page--functionbar">
  <mock-functionbar>
    <mock-functionbar-item icon="fa-arrow-left" navigationLink=".." slot="left-container"
                           tooltip="common.back"></mock-functionbar-item>
    <mock-functionbar-item (clicked)="notifyJournalEntry()" text="Tagebuch Eintrag melden" slot="right-container"
                           tooltip="Sendet eine notifyJournalEntry Meldung"></mock-functionbar-item>
    <mock-functionbar-item (clicked)="notifyRegistration()" text="Rechtsgültig melden" slot="right-container"
                           tooltip="Sendet eine notifyRegistration Meldung"></mock-functionbar-item>
    <mock-functionbar-item (clicked)="notifyRejection()" text="Abweisen" slot="right-container"
                           tooltip="Weist ein Geschäft ab"></mock-functionbar-item>
    <mock-functionbar-item (clicked)="requestImprovedDocuments()" text="Dokumente nachfordern" slot="right-container"
                           tooltip="Sendet eine requestImprovedDocuments Meldung"></mock-functionbar-item>
    <mock-functionbar-item (clicked)="cancelRequestImprovedDocuments()" text="Nachforderung abbrechen" slot="right-container"
                           tooltip="Sendet eine cancelRequestImprovedDocuments Meldung"></mock-functionbar-item>
    <mock-functionbar-item (clicked)="changeDeadline()" text="Frist ändern" slot="right-container"
                           tooltip="Sendet eine changeDeadline Meldung"></mock-functionbar-item>
  </mock-functionbar>

  <div *ngIf="info">
    <div class="alert alert-info" role="alert">
      {{info}}
    </div>
  </div>

  <div *ngIf="error">
    <div class="alert alert-danger" role="alert">
      {{error}}
    </div>
  </div>

  <div>
    <div class="col">
      <div class="row">
        <mock-card [title]="title">
          <mock-form-control [label]="{'id': 'nummer', 'key': 'Nummer'}" [readonly]="true">
            <input type="text" class="form-control" [value]="notariatsgeschaeft.nummer">
          </mock-form-control>
          <mock-form-control [label]="{'id': 'document', 'key': 'Dokument'}" [readonly]="true">
            <pre class="form-control">{{notariatsgeschaeft.document}}</pre>
          </mock-form-control>
        </mock-card>
      </div>
      <div class="row">
        <mock-card title="Improved Documents" *ngIf="notariatsgeschaeft.improvedDocuments && notariatsgeschaeft.improvedDocuments.length > 0">
            <pre class="form-control" *ngFor="let improvedDocument of notariatsgeschaeft.improvedDocuments">{{improvedDocument}}</pre>
        </mock-card>
      </div>
    </div>
  </div>
</div>
