<fieldset [disabled]="readonly">
  <div [class.mb-3]="!hideLabel" [class.row]="isLarge()">
    <label *ngIf="label" [for]="label.id" [id]="label.id + 'Label'"
           class="text-muted" [class.col-form-label]="isLarge()" [class.hide-label]="hideLabel" [ngClass]="colClasses[0]"><span [class.required]="isRequired()">{{label.key}}</span>
      <span *ngIf="infoMessage" class="text-end ps-1">
        <i class="fas fa-info-circle">
        </i>
      </span>
    </label>
    <ng-container [ngTemplateOutlet]="isLarge() ? large : small"></ng-container>
  </div>
</fieldset>

<ng-template #large>
  <div [ngClass]="colClasses[1]">
    <ng-container [ngTemplateOutlet]="small"></ng-container>
  </div>
</ng-template>

<ng-template #small>
  <ng-content></ng-content>
  <div *ngIf="(innerControl?.statusChanges | async) === 'INVALID' || innerControl?.touched" class="invalid-feedback">
    <ng-container *ngFor="let error of getErrorKeys(innerControl?.errors)">
      <small>{{translateError(error)}}</small><br/>
    </ng-container>
  </div>
</ng-template>
