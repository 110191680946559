import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActionType} from '../../enum/action-type.enum';

@Component({
  selector: 'mock-functionbar-item',
  templateUrl: './functionbar-item.component.html',
  styleUrls: ['./functionbar-item.component.scss']
})
export class FunctionbarItemComponent {
  @Input() public navigationLink!: string;
  @Input() public tooltip!: string;
  @Input() public icon!: string;
  @Input() public text!: string;
  @Input() public invalid = false;
  @Input() public queryParams: any;
  @Input() public actionType!: ActionType;
  @Input() public solid = true;
  @Output() public clicked = new EventEmitter<any>();

  onClicked(): void {
    this.clicked.emit();
  }

  isPrimary(): boolean {
    return ActionType.PRIMARY === this.actionType || !this.actionType;
  }

  isDanger(): boolean {
    return ActionType.DANGER === this.actionType;
  }

}
