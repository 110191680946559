import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LayoutModule} from './layout/layout.module';
import {GrundstueckListComponent} from './grundstueck-list/grundstueck-list.component';
import {ApiModule, BASE_PATH, Configuration} from '../../target/generated-sources/openapi';
import {HttpClientModule} from '@angular/common/http';
import {GrundstueckComponent} from './grundstueck/grundstueck.component';
import {SharedModule} from './shared/shared.module';
import {GbdbsListComponent} from './gbdbs-list/gbdbs-list.component';
import {GbdbsDetailComponent} from './gbdbs-detail/gbdbs-detail.component';
import {NotariatsgeschaeftDetailComponent} from './notariatsgeschaeft-detail/notariatsgeschaeft-detail.component';
import {NotariatsgeschaeftListComponent} from './notariatsgeschaeft-list/notariatsgeschaeft-list.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {UpregComponent} from './upreg/upreg.component';

@NgModule({
  declarations: [
    AppComponent,
    GrundstueckListComponent,
    GrundstueckComponent,
    GbdbsListComponent,
    GbdbsDetailComponent,
    NotariatsgeschaeftDetailComponent,
    NotariatsgeschaeftListComponent,
    UpregComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    HttpClientModule,
    ApiModule.forRoot(() => new Configuration()),
    SharedModule,
    NgbModule,
    FontAwesomeModule
  ],
  providers: [
    {
      provide: BASE_PATH,
      useValue: ''
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
