<aside class="app-sidebar">
  <div class="sidebar-content">
    <div class="sidebar-menu h-100">
      <nav class="nav flex-column sidebar-nav h-100">
        <ul class="menu">
          <li class="menu-item">
            <a class="nav-link menu-link" routerLink="/grundstueck" routerLinkActive="active">
              <i class="menu-icon fa-solid fa-map"></i>
              <span class="menu-label">Grundstücke</span>
            </a>
          </li>
          <li class="menu-item">
            <a class="nav-link menu-link" routerLink="/persongb" routerLinkActive="active">
              <i class="menu-icon fa-solid fa-users"></i>
              <span class="menu-label">Personen</span>
            </a>
          </li>
          <li class="menu-item">
            <a class="nav-link menu-link" routerLink="/recht" routerLinkActive="active">
              <i class="menu-icon fa-solid fa-scroll"></i>
              <span class="menu-label">Rechte</span>
            </a>
          </li>
          <li class="menu-item">
            <a class="nav-link menu-link" routerLink="/notariatsgeschaeft" routerLinkActive="active">
              <i class="menu-icon fa-solid fa-handshake"></i>
              <span class="menu-label">Notariatsgeschaeft</span>
            </a>
          </li>
          <li class="menu-item">
            <a class="nav-link menu-link" routerLink="/emescad" routerLinkActive="active">
              <i class="menu-icon fa-solid fa-box-archive"></i>
              <span class="menu-label">eMescad Archiv</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</aside>
