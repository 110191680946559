import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'mock-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() title?: string;
  @Input() formGroup?: FormGroup;
  @Input() initEdit = false;
  @Input() canReset = true;
  @Input() disableButtons = false;
  @Output() cardSubmit = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    this.initEdit ? this.formGroup?.enable() : this.disableButtons ? this.formGroup?.enable() : this.formGroup?.disable();
  }

  onSubmit(): void {
    this.formGroup?.markAllAsTouched();
    if (this.formGroup?.valid && !this.disableButtons) {
      this.cardSubmit.emit();
      this.formGroup.disable();
    }
  }

  onReset(): void {
    this.formGroup?.disable();
  }

  onEdit(): void {
    this.formGroup?.enable();
  }
}
