import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GrundstueckDTO } from '../../../target/generated-sources/openapi';

@Component({
  selector: 'mock-grundstueck',
  templateUrl: './grundstueck.component.html',
  styleUrls: ['./grundstueck.component.scss']
})
export class GrundstueckComponent implements OnInit {

  grundstueck!: GrundstueckDTO;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.grundstueck = this.route.snapshot.data.grundstueck;
  }

}
