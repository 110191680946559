import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {NotariatsgeschaeftControllerService, NotariatsgeschaeftDTO} from "../../../target/generated-sources/openapi";

@Injectable({
  providedIn: 'root'
})
export class NotariatsgeschaeftListResolver implements Resolve<NotariatsgeschaeftDTO[]> {

  constructor(private backend: NotariatsgeschaeftControllerService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NotariatsgeschaeftDTO[]> {
    return this.backend.getNotariatsgeschaeftList();
  }
}
