/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ReferenceType { 
    uuid: string;
    serviceInventoryId?: string;
    serviceId?: string;
    serviceTitle?: string;
    serviceProvider?: string;
    caseId?: string;
    caseTitle?: string;
    caseAnnotation?: string;
}

