/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NamedNodeMap } from './namedNodeMap';
import { Node } from './node';
import { NodeList } from './nodeList';
import { Document } from './document';


export interface DocumentType { 
    entities?: NamedNodeMap;
    notations?: NamedNodeMap;
    systemId?: string;
    publicId?: string;
    internalSubset?: string;
    name?: string;
    nodeType?: number;
    nodeName?: string;
    nodeValue?: string;
    parentNode?: Node;
    firstChild?: Node;
    lastChild?: Node;
    previousSibling?: Node;
    nextSibling?: Node;
    ownerDocument?: Document;
    baseURI?: string;
    textContent?: string;
    attributes?: NamedNodeMap;
    prefix?: string;
    childNodes?: NodeList;
    localName?: string;
    namespaceURI?: string;
}

