import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {EMPTY, Observable} from 'rxjs';
import {NotariatsgeschaeftControllerService, NotariatsgeschaeftDTO} from "../../../target/generated-sources/openapi";

@Injectable({
  providedIn: 'root'
})
export class NotariatsgeschaeftResolver implements Resolve<NotariatsgeschaeftDTO> {

  constructor(private backend: NotariatsgeschaeftControllerService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NotariatsgeschaeftDTO> {
    const id = route.paramMap.get('id');
    if (!id) {
      return EMPTY;
    }

    return this.backend.getNotariatsgeschaeftById(id);

  }
}
