<!--Placement Auto is a workaround: https://github.com/ng-bootstrap/ng-bootstrap/issues/2924-->
<div class="functionbar-item">
  <a *ngIf="navigationLink; else noLink" [class.functionbar-item__action--primary]="isPrimary()"
     [class.functionbar-item__action--danger]="isDanger()"
     [queryParams]="queryParams"
     [routerLink]="navigationLink"
     class="functionbar-item__action">
    <i *ngIf="icon" [class]="icon" [ngClass]="solid ? 'fas' : 'far'"></i>
    <span *ngIf="text" class="ps-1 pe-1 d-flex">{{text}}</span>
  </a>

  <ng-template #noLink>
    <button *ngIf="icon || text" (click)="onClicked()" [class.functionbar-item__action--primary]="isPrimary()"
            [class.functionbar-item__action--danger]="isDanger()" class="functionbar-item__action">
      <span *ngIf="invalid" class="badge rounded-pill badge-warning me-1">!</span>
      <i *ngIf="icon" [class]="icon" [ngClass]="solid ? 'fas' : 'far'"></i>
      <span *ngIf="text" class="ps-1 pe-1 d-flex">{{text}}</span>
    </button>
  </ng-template>

</div>
