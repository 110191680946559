import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from './layout/layout/layout.component';
import {GrundstueckListResolver} from './resolver/grundstueck-list.resolver';
import {GrundstueckResolver} from './resolver/grundstueck.resolver';
import {GbdbsListComponent} from './gbdbs-list/gbdbs-list.component';
import {GbdbsDetailComponent} from './gbdbs-detail/gbdbs-detail.component';
import {PersongbListResolver} from './resolver/persongb-list.resolver';
import {PersongbResolver} from './resolver/persongb.resolver';
import {RechtResolver} from './resolver/recht.resolver';
import {RechtListResolver} from './resolver/recht-list.resolver';
import {NotariatsgeschaeftListResolver} from './resolver/notariatsgeschaeft-list.resolver';
import {NotariatsgeschaeftResolver} from './resolver/notariatsgeschaeft.resolver';
import {NotariatsgeschaeftDetailComponent} from './notariatsgeschaeft-detail/notariatsgeschaeft-detail.component';
import {NotariatsgeschaeftListComponent} from './notariatsgeschaeft-list/notariatsgeschaeft-list.component';
import {UpregComponent} from './upreg/upreg.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'grundstueck',
        pathMatch: 'full'
      },
      {
        path: 'grundstueck',
        component: GbdbsListComponent,
        resolve: {
          list: GrundstueckListResolver
        }
      },
      {
        path: 'grundstueck/:id',
        component: GbdbsDetailComponent,
        data: {
          title: 'Grundstück'
        },
        resolve: {
          entity: GrundstueckResolver,
        }
      },
      {
        path: 'persongb',
        component: GbdbsListComponent,
        resolve: {
          list: PersongbListResolver
        }
      },
      {
        path: 'persongb/:id',
        component: GbdbsDetailComponent,
        data: {
          title: 'PersonGB'
        },
        resolve: {
          entity: PersongbResolver,
        }
      },
      {
        path: 'recht',
        component: GbdbsListComponent,
        resolve: {
          list: RechtListResolver
        }
      },
      {
        path: 'recht/:id',
        component: GbdbsDetailComponent,
        data: {
          title: 'Recht'
        },
        resolve: {
          entity: RechtResolver,
        }
      },
      {
        path: 'notariatsgeschaeft',
        component: NotariatsgeschaeftListComponent,
        resolve: {
          list: NotariatsgeschaeftListResolver
        }
      },
      {
        path: 'notariatsgeschaeft/:id',
        component: NotariatsgeschaeftDetailComponent,
        data: {
          title: 'Notariatsgeschaeft'
        },
        resolve: {
          entity: NotariatsgeschaeftResolver
        }
      },
      {
        path: 'upreg',
        component: UpregComponent
      },
      {
        path: 'emescad',
        loadChildren: () => import('./emescad/emescad.module').then(m => m.EmescadModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
