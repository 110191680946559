<div class="functionbar">
  <div class="functionbar__container functionbar__left-container">
    <ng-content select="[slot=left-container]"></ng-content>
  </div>
  <div class="functionbar__container functionbar__middle-container">
    <ng-content select="[slot=middle-container]"></ng-content>
  </div>
  <div class="functionbar__container functionbar__right-container">
    <ng-content select="[slot=right-container]"></ng-content>
  </div>
</div>
