/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Layout { 
    page?: Layout.PageEnum;
    left_pos?: number;
    top_pos?: number;
}
export namespace Layout {
    export type PageEnum = 'FIRST' | 'PENULTIMATE' | 'ULTIMATE';
    export const PageEnum = {
        First: 'FIRST' as PageEnum,
        Penultimate: 'PENULTIMATE' as PageEnum,
        Ultimate: 'ULTIMATE' as PageEnum
    };
}


