import {Component, Input} from '@angular/core';

@Component({
  selector: 'mock-pagination-total',
  templateUrl: './pagination-total.component.html',
  styleUrls: ['./pagination-total.component.scss']
})
export class PaginationTotalComponent {

  @Input()
  total = 0;

}
