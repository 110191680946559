<div class="table-responsive">
  <table class="table table-striped table-hover">
    <thead>
    <tr>
      <th scope="col">ID</th>
      <th scope="col">Nummer</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let grundstueck of list" [routerLink]="'./' + grundstueck.id" class="navigation-table__row">
      <td>{{grundstueck.id}}</td>
      <td>{{grundstueck.nummer}}</td>
    </tr>
    </tbody>
  </table>
</div>
