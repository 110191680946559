import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutControlService {

  localStorageName = 'notap_sidebar_expanded';

  sidebarExpanded: boolean;

  constructor() {
    this.sidebarExpanded = window.localStorage.getItem(this.localStorageName) !== 'false';
  }

  toggleSidebar(): void {
    this.sidebarExpanded = !this.sidebarExpanded;
    window.localStorage.setItem(this.localStorageName, this.sidebarExpanded ? 'true' : 'false');
  }

  isSidebarExpanded(): boolean {
    return this.sidebarExpanded;
  }

}
