import {Component, OnInit} from '@angular/core';
import {NotariatsgeschaeftDTO} from "../../../target/generated-sources/openapi";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'mock-notariatsgeschaeft-list',
  templateUrl: './notariatsgeschaeft-list.component.html',
  styleUrls: ['./notariatsgeschaeft-list.component.scss']
})
export class NotariatsgeschaeftListComponent implements OnInit {

  list!: NotariatsgeschaeftDTO[];

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.list = this.route.snapshot.data.list;
  }
}
