import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormControlLabel } from './form-control-label';

@Component({
  selector: 'mock-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss']
})
export class FormControlComponent implements OnInit {

  @Input()
  label!: FormControlLabel;
  @Input()
  hideLabel = false;
  @Input()
  readonly?: boolean;
  @Input()
  infoMessage?: string;
  @Input()
  innerControl!: AbstractControl | null;
  @Input()
  errorMessages: { [k: string]: string } = {};
  @Input()
  size: 'LARGE' | 'SMALL' = 'LARGE';
  @Input()
  colClasses: string[] = ['', ''];

  constructor() {
  }

  ngOnInit(): void {
  }

  translateError(key: string): any {
    return key;
  }

  getErrorKeys(errors?: ValidationErrors | null): string[] {
    if (errors) {
      return Object.keys(errors);
    }
    return [];
  }

  isRequired(): boolean {
    return this.innerControl?.validator && this.innerControl?.validator({} as AbstractControl)?.required;
  }

  isLarge(): boolean {
    return this.size === 'LARGE';
  }
}
