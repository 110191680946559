import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GrundstueckControllerService, GrundstueckDTO } from '../../../target/generated-sources/openapi';

@Injectable({
  providedIn: 'root'
})
export class GrundstueckListResolver implements Resolve<GrundstueckDTO[]> {

  constructor(private backend: GrundstueckControllerService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GrundstueckDTO[]> {
    return this.backend.getGrundstueckList();
  }
}
