import { Component, OnInit } from '@angular/core';
import { LayoutControlService } from '../service/layout-control.service';

@Component({
  selector: 'mock-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  constructor(
    private layoutControl: LayoutControlService,
  ) {
  }

  ngOnInit(): void {
  }

  toggleSidebar(): void {
    this.layoutControl.toggleSidebar();
  }

}
