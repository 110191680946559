import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardComponent} from './component/card/card.component';
import {FormControlComponent} from './component/form-control/form-control.component';
import {FunctionbarComponent} from './component/functionbar/functionbar.component';
import {FunctionbarItemComponent} from './component/functionbar-item/functionbar-item.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {PaginationTotalComponent} from './component/pagination-total/pagination-total.component';


@NgModule({
  declarations: [
    CardComponent,
    FormControlComponent,
    FunctionbarComponent,
    FunctionbarItemComponent,
    PaginationTotalComponent
  ],
  exports: [
    CardComponent,
    FormControlComponent,
    FunctionbarComponent,
    FunctionbarItemComponent,
    FormsModule,
    NgbPaginationModule,
    PaginationTotalComponent,
    ReactiveFormsModule,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbPaginationModule,
    ReactiveFormsModule,
    RouterModule
  ]
})
export class SharedModule {
}
