import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { RechtControllerService, RechtDTO } from '../../../target/generated-sources/openapi';

@Injectable({
  providedIn: 'root'
})
export class RechtResolver implements Resolve<RechtDTO> {

  constructor(private backend: RechtControllerService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RechtDTO> {
    const id = route.paramMap.get('id');
    if (!id) {
      return EMPTY;
    }
    return this.backend.getRechtById(id);
  }

}
