<div class="card border-0">
  <ng-container *ngTemplateOutlet="formGroup ? form : content"></ng-container>
</div>

<ng-template #content>
  <div class="card-body">
    <div class="card-title">
      <div class="float-end">
        <ng-content select=".card__button"></ng-content>
        <ng-container *ngTemplateOutlet="formGroup ? !disableButtons ? formButtons : null : null"></ng-container>
      </div>
      <h2 *ngIf="title" class="card__header-title mb-3 pt-2">{{title}}</h2>
      <div class="clearfix"></div>
    </div>
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-template #form>
  <form (ngSubmit)="onSubmit()" (reset)="onReset()" [formGroup]="formGroup!">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </form>
</ng-template>

<ng-template #formButtons>
  <button *ngIf="formGroup!.enabled && canReset"
          class="btn btn-link btn-simple-primary card__button"
          type="reset">
    <i class="fas fa-times"></i>
  </button>
  <button *ngIf="formGroup!.enabled"
          class="btn btn-link btn-simple-primary card__button"
          type="submit">
    <i class="fas fa-check"></i>
  </button>
  <button (click)="onEdit()" *ngIf="formGroup!.disabled"
          class="btn btn-link btn-simple-primary card__button" type="button">
    <i class="fas fa-edit"></i>
  </button>
</ng-template>
