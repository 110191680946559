export * from './eMescadArchiveControllerImpl.service';
import { EMescadArchiveControllerImplService } from './eMescadArchiveControllerImpl.service';
export * from './eMescadSealControllerImpl.service';
import { EMescadSealControllerImplService } from './eMescadSealControllerImpl.service';
export * from './grundstueckController.service';
import { GrundstueckControllerService } from './grundstueckController.service';
export * from './notariatsgeschaeftController.service';
import { NotariatsgeschaeftControllerService } from './notariatsgeschaeftController.service';
export * from './persongbController.service';
import { PersongbControllerService } from './persongbController.service';
export * from './rechtController.service';
import { RechtControllerService } from './rechtController.service';
export * from './upRegControllerImpl.service';
import { UpRegControllerImplService } from './upRegControllerImpl.service';
export const APIS = [EMescadArchiveControllerImplService, EMescadSealControllerImplService, GrundstueckControllerService, NotariatsgeschaeftControllerService, PersongbControllerService, RechtControllerService, UpRegControllerImplService];
