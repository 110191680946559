import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PersongbControllerService, PersongbDTO } from '../../../target/generated-sources/openapi';

@Injectable({
  providedIn: 'root'
})
export class PersongbListResolver implements Resolve<PersongbDTO[]> {

  constructor(private backend: PersongbControllerService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PersongbDTO[]> {
    return this.backend.getPersongbList();
  }

}
